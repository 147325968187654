import React, { cloneElement } from 'react'
import Marquee from 'react-fast-marquee'
import  styled  from 'styled-components'
import {
    Text,
    useMatchBreakpoints,
  } from '@pancakeswap/uikit'

  

  const TagWrapper = styled.div`
    display: flex;
    padding: 8px 12px;
    height: 38px;
    justify-content: start;
    align-items: center;
    border-radius: 10px;
    margin-right: 8px;
    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 12px 24px;
      height: 46px;
      border-radius: 10px;
      margin-right: 12px;
    }
  `
  
  const StyledMarquee = styled(Marquee)`
    width: 100%;
    max-width: 2000px;
    mask-image: linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent);
    -webkit-mask-image: linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent);
    border-radius: 12px;
  `
  
  const StyledChainIcon = styled('div')`
    svg path {
      fill: ${({ theme }) => theme.colors.invertedContrast};
    }
  `
  
  const newsItems = [
    {
      key: 'GANGVERSE NEWS: 📢 Gangverse App on Crypto.com DeFi Wallet Application Right Now ! 📢 Active Stake: Gangverse Darkside & Yurosako & Life Of Hel & Crononymous 📢 Total Active Project: 4 📢 Upcoming Stake: Gangverse Social Club...',
      component: (
        <StyledChainIcon>
        <img src="/images/banners/small/gangverse.png" alt="rewards" width={30} height={30}  />
        </StyledChainIcon>
      ),
      background: '#151c21',
      iconWidth: '20px',
      textColor: '#ffffff', // Yeni eklenen özellik: metin rengi
      fontSize: '16px', // Yeni eklenen özellik: metin boyutu
    },
    
  ]
  
  export const ChainTags: React.FC = () => {
    const { isMobile } = useMatchBreakpoints();
    return (
      <StyledMarquee>
        {newsItems.map((d, index) => (
          <TagWrapper background={d.background} key={index}>
            {cloneElement(d.component, { width: d.iconWidth, color: 'invertedContrast' })}
            <Text
              fontWeight={600}
              fontSize={d.fontSize || (isMobile ? '16px' : '20px')}
              ml="10px"
              color={d.textColor || 'invertedContrast'}
            >
              {d.key}
            </Text>
          </TagWrapper>
        ))}
      </StyledMarquee>
    )
  }